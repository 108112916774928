<template>
  <div v-if="ready">
    <b-button
      class="mb-2"
      variant="primary-custom"
      @click="$router.push(`/contas_bancarias/${$route.params.unityId}`)"
    >
      <fa-icon icon="arrow-left" class="mr-2"></fa-icon> Voltar
    </b-button>
    <Card :title="'Nova Conta Bancária'">
      <AddEditForm :bankList="bankList" />
    </Card>
  </div>
</template>

<script>
export default {
  data() {
    return {
      ready: false,
    };
  },
  components: {
    AddEditForm: () => import("./components/AddEditForm"),
  },
  computed: {
    bankList() {
      return _.map(this.$store.state.unities.bankList, (bank) => {
        return { value: bank.id, text: bank.description };
      });
    },
  },
  async mounted() {
    await this.$store.dispatch("unities/bank_accounts_add_edit_init");
    this.ready = true;
  },
};
</script>

<style></style>
